import React from "react";
import {graphql} from "gatsby";
import {Helmet} from "react-helmet";
import iconDownloadWhite from "../../images/icons/icon-download-white.svg";
import iconDownloadColored from "../../images/icons/icon-download-colored.svg";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";

import BlocImage from "../../components/dynamicZone/blocImage/blocImage";
import HeaderImage from "../../components/header/headerImage/headerImage";
import NumberCarousel from "../../components/carousel/numbersCarousel/numbersCarousel";

import "./style.scss";

// bloc image
import imgBlocImage from "../../images/chiffres-cles/photo-bloc-image.webp";

// fichiers
import rapportAnnuelAuxiliaire2023 from "../../../static/pdfs/Rapport_Annuel_L_Auxiliaire_-_2023_.pdf"
import rapportAnnuelAuxiliaireVie2023 from "../../../static/pdfs/Rapport_Annuel_L_Auxiliaire_Vie_-_2023.pdf"

import statutAux2022 from "../../../static/pdfs/STATUTS_-_L_Auxiliaire_-_2022.pdf"
import statutAuxVie2022 from "../../../static/pdfs/STATUTS_-_L_Auxiliaire_Vie_-_2022.pdf"
import rendement from "../../../static/pdfs/20241231_-_Publication_des_taux_moyen_de_rendement_et_d_intérêt_garantis.pdf"
import bilanDesherence from "../../../static/pdfs/bilan_desherence_2020.pdf"
import bilanDesherence2021 from "../../../static/pdfs/bilan_desherence_2021.pdf"
import bilanDesherence2022 from "../../../static/pdfs/Reporting_2022_pour_internet_Auxiliaire_-__Nouvelle_charte.pdf"

import PERindiviuels from "../../../static/pdfs/PERindiviuels.pdf"
import indexFemmesHommes from "../../../static/pdfs/Index_femmes-hommes_2024_pour_publication_2025.pdf"
import fraisContratVieCommercialisation from "../../../static/pdfs/fraisContratVieCommercialisation.pdf"


//RSSF
import rapportAuxiliaireRSSF2017 from "../../../static/pdfs/lauxiliaire_-_sfcr_2017.pdf"
import rapportAuxiliaireVieRSSF2017 from "../../../static/pdfs/lauxiliaire_vie_-_sfcr_2017.pdf"
import rapportAuxiliaireRSSF2018 from "../../../static/pdfs/lauxiliaire_-_sfcr_-_2018_0.pdf"
import rapportAuxiliaireVieRSSF2018 from "../../../static/pdfs/lauxiliaire_vie_-_sfcr_-_2018.pdf"
import rapportAuxiliaireRSSF2019 from "../../../static/pdfs/lauxiliaire_-_sfcr_-_2019.pdf"
import rapportAuxiliaireVieRSSF2019 from "../../../static/pdfs/lauxiliaire_vie_-_sfcr_-_2019.pdf"
import rapportAuxiliaireRSSF2020 from "../../../static/pdfs/lauxiliaire_-_sfcr_-_2020.pdf"
import rapportAuxiliaireVieRSSF2020 from "../../../static/pdfs/lauxiliaire_vie_-_sfcr_2020.pdf"
import rapportAuxiliaireRSSF2021 from "../../../static/pdfs/lauxiliaire_-_sfcr_-_2021.pdf"
import rapportAuxiliaireVieRSSF2021 from "../../../static/pdfs/lauxiliaire_vie_-_sfcr_2021.pdf"
import rapportAuxiliaireRSSF2022 from "../../../static/pdfs/L_Auxiliaire_-_SFCR_-_2022.pdf"
import rapportAuxiliaireVieRSSF2022 from "../../../static/pdfs/L_Auxiliaire_Vie_-_SFCR_2022.pdf"

import rapportAuxiliaireRSSF2023 from "../../../static/pdfs/L_Auxiliaire_-_SFCR_2023.pdf"
import rapportAuxiliaireVieRSSF2023 from "../../../static/pdfs/L_Auxiliaire_Vie_-_SFCR_2023.pdf"

//SGAM
import rapportAuxiliaireSGAM2022 from "../../../static/pdfs/rapport_sur_la_solvabilite_et_la_situation_financiere_2022_de_sgam_btp.pdf"
import rapportAuxiliaireSGAM2021 from "../../../static/pdfs/sfcr_2021_sgam_btp_rapport.pdf"
import rapportAuxiliaireSGAM2020 from "../../../static/pdfs/sfcr_2020_sgam_btp_rapport.pdf"
import rapportAuxiliaireSGAM2019 from "../../../static/pdfs/sgam_btp_sfcr_2019.pdf"
import rapportAuxiliaireSGAM2018 from "../../../static/pdfs/sgambtp_sfcr_2018.pdf"
import rapportAuxiliaireSGAM2017 from "../../../static/pdfs/rapportsfcr2017_sgambtp_vdef.pdf"
import rapportAuxiliaireSGAM2016 from "../../../static/pdfs/sfcr_sgambtp_2016.pdf"

// RSE / DURABME
import declarationIncidencesNegativesDurabiliteAuxiliaireVie from "../../../static/pdfs/Auxiliaire_Vie_-_Déclaration_de_non-prise_en_compte_des_incidences_négatives_en_matière_de_durabilité.pdf"
import rapportArticle29LECAuxiliaireVie from "../../../static/pdfs/Auxiliaire_Vie_-_Rapport_Article_29_LEC.pdf"

// header
import imgHeader from "../../images/chiffres-cles/header.webp";
import Rapport from "../../components/rapport";

const ChiffresCles = ({ location }) => {

  const numbers = [
    {
      Number: "15000",
      right_text: "clients-sociétaires",
    },
    {
      Number: "250",
      right_text: "collaborateurs",
    },
    {
      Number: "96",
      right_text: "% de clients satisfaits**",
    },
    {
      Number: "251",
      right_text: "M€ de chiffre d'affaires",
    },
    {
      Number: "383",
      right_text: "M€ de fonds propres*",
    },
    {
      left_text: "Plus de",
      Number: "300",
      right_text: "courtiers partenaires",
    },
  ];

  const rapportSolvabiliteRSSF = [
    {
      rapportAuxiliaire: rapportAuxiliaireRSSF2023,
      rapportAuxiliaireVie: rapportAuxiliaireVieRSSF2023,
      exercice: 2023
    },
    {
      rapportAuxiliaire: rapportAuxiliaireRSSF2022,
      rapportAuxiliaireVie: rapportAuxiliaireVieRSSF2022,
      exercice: 2022
    },
    {
      rapportAuxiliaire: rapportAuxiliaireRSSF2021,
      rapportAuxiliaireVie: rapportAuxiliaireVieRSSF2021,
      exercice: 2021
    },
    {
      rapportAuxiliaire: rapportAuxiliaireRSSF2020,
      rapportAuxiliaireVie: rapportAuxiliaireVieRSSF2020,
      exercice: 2020
    },
    {
      rapportAuxiliaire: rapportAuxiliaireRSSF2019,
      rapportAuxiliaireVie: rapportAuxiliaireVieRSSF2019,
      exercice: 2019
    },
    {
      rapportAuxiliaire: rapportAuxiliaireRSSF2018,
      rapportAuxiliaireVie: rapportAuxiliaireVieRSSF2018,
      exercice: 2018
    },
    {
      rapportAuxiliaire: rapportAuxiliaireRSSF2017,
      rapportAuxiliaireVie: rapportAuxiliaireVieRSSF2017,
      exercice: 2017
    }
  ];
  const rapportSolvabiliteSGAM = [
    {
      rapportAuxiliaire: rapportAuxiliaireSGAM2022,
      exercice: 2022
    },
    {
      rapportAuxiliaire: rapportAuxiliaireSGAM2021,
      exercice: 2021
    },
    {
      rapportAuxiliaire: rapportAuxiliaireSGAM2020,
      exercice: 2020
    },
    {
      rapportAuxiliaire: rapportAuxiliaireSGAM2019,
      exercice: 2019
    },
    {
      rapportAuxiliaire: rapportAuxiliaireSGAM2018,
      exercice: 2018
    },
    {
      rapportAuxiliaire: rapportAuxiliaireSGAM2017,
      exercice: 2017
    },
    {
      rapportAuxiliaire: rapportAuxiliaireSGAM2016,
      exercice: 2016
    },
  ];

  return (
    <>
      <Helmet>
        <body className="chiffres-cles" />
      </Helmet>
      <Layout>
        <SEO title="Chiffres Clés" />
        <HeaderImage
          title={<>Chiffres&nbsp;clés et<br/>informations</>}
          text="Confier ses risques à L’Auxiliaire, c’est être certain de trouver à ses côtés un partenaire historique de votre secteur d’activité, pérenne et sérieux qui maîtrise parfaitement la gestion des risques longs et complexes."
          imagePath={imgHeader}
          location={location}
          crumbLabel="Chiffres Clés"
        />
        <section className="chiffres-cles__section-numbers">
          <div className={'block-container'}>
            <h2>
              Nos chiffres <strong>clés</strong>
            </h2>
          </div>
          <NumberCarousel numbers={numbers} />
        </section>
        <p className="chiffres-cles__description">* 2023 - Chiffre d’affaires apporté et distribué<br/>** Solvabilité 2<br/>*** Enquête Opinion Way auprès de 300 clients – Nov 2024</p>



        {/* NOS STATUS & CONTRAT DESHERENCE */}
        <section className={"box-chiffres-cles"}>
          <div className={"box-titles"}>
            {/* GAUCHE */}
            <div>
              <h2>
                Nos <strong>statuts</strong>
              </h2>
            </div>

            {/* DROITE */}
            <div>
              <h2>
                Contrats en <strong>déshérence</strong>
              </h2>
            </div>
          </div>

          <div className={"box-contents"}>
            {/* GAUCHE */}
            <div>
              <div className="divLink">
                Télécharger les statuts de{" "}
                <a href={statutAux2022} className="btn-file--3" download>
                  <img src={iconDownloadColored} alt="icone download" />
                  <span>L'Auxiliaire</span>
                </a>
                {" "}ou de{" "}
                <a href={statutAuxVie2022} className="btn-file--3" download>
                  <img src={iconDownloadColored} alt="icone download" />
                  <span>L'Auxiliaire Vie</span>
                </a>
              </div>
            </div>

            {/* DROITE */}
            <div>
              <a href={bilanDesherence2022} className="btn-file--2" download>
                <img src={iconDownloadColored} alt="icone download" />
                <span>Bilan des actions 2022</span>
              </a>

              <a href={bilanDesherence2021} className="btn-file--2" download>
                <img src={iconDownloadColored} alt="icone download" />
                <span>Bilan des actions 2021</span>
              </a>

              <a href={bilanDesherence} className="btn-file--2" download>
                <img src={iconDownloadColored} alt="icone download" />
                <span>Bilan des actions 2020</span>
              </a>
            </div>
          </div>
        </section>


        {/* RAPPORT SUR LA SOLVABILITE */}
        <section className={"box-chiffres-cles"}>
          <h2 className={"box-full-title"}>
            Rapports sur la{" "}
            <strong>solvabilité et la situation financière</strong>
          </h2>

          <div className={"box-titles"}>
            {/* GAUCHE */}
            <div>
              <span>
                Rapports sur la Solvabilité et la Situation Financière (SFCR / RSSF) :
              </span>
            </div>

            {/* DROITE */}
            <div>
              <span>
                Téléchargez le Rapport sur la Solvabilité et la Situation Financière
                (SFCR) de la SGAM btp :
              </span>
            </div>
          </div>

          <div className={"box-contents"}>
            {/* GAUCHE */}
            <div>
              <div className="divLinks">
                {rapportSolvabiliteRSSF.map(rapport => {
                  return <Rapport key={rapport.exercice} exercice={rapport.exercice}
                                  rapportAuxiliaire={rapport.rapportAuxiliaire}
                                  rapportAuxiliaireVie={rapport.rapportAuxiliaireVie}/>
                })}
              </div>
            </div>

            {/* DROITE */}
            <div>
              <div className="divLinks">
                {rapportSolvabiliteSGAM.map(rapport =>
                    <Rapport
                        key={rapport.exercice}
                        exercice={rapport.exercice}
                        rapportAuxiliaire={rapport.rapportAuxiliaire}
                        titre="la SGAM BTP"
                    />
                )}
              </div>
            </div>
          </div>
        </section>



        <BlocImage title="<strong>Rapports annuels</strong>" imagePath={imgBlocImage}>
          <p>Téléchargez nos rapports annuels 2023</p>
          <div className="chiffres-cles__rapport-container">
            <a
                href={rapportAnnuelAuxiliaire2023}
                className="btn-file--1"
                download="Rapport_Annuel_L_Auxiliaire_2023.pdf"
            >
              <img src={iconDownloadWhite} alt="icone download" />
              <span>L'Auxiliaire</span>
            </a>
            <a
                href={rapportAnnuelAuxiliaireVie2023}
                className="btn-file--1"
                download="Rapport_Annuel_L_Auxiliaire_Vie_2023.pdf"
            >
              <img src={iconDownloadWhite} alt="icone download" />
              <span>L'Auxiliaire vie</span>
            </a>
          </div>
        </BlocImage>


        {/* RENDEMENT DES CONTRAT & INDEX EGALITE HOMME FEMME */}
        <section className={"box-chiffres-cles"}>
          <div className={"box-titles"}>
            {/* GAUCHE */}
            <div>
              <h2>
                Rendements des contrats
                <strong> d’assurance vie et de capitalisation</strong>
              </h2>
            </div>

            {/* DROITE */}
            <div>
              <h2>
                Index égalité <br></br>
                <strong>Femmes-Hommes</strong>
              </h2>
              <span>Notre index égalité Femmes-Hommes en 2024 est de 90/100 pour notre UES L’Auxiliaire et L’Auxiliaire Vie (mise à jour 21 février 2025).</span>
            </div>
          </div>

          <div className={"box-contents"}>
            {/* GAUCHE */}
            <div>
              <a href={rendement} className="btn-file--2" download>
                <img src={iconDownloadColored} alt="icone download" />
                <span>
                Consultez les taux moyens de rendement et taux moyens d'intérêt garantis des contrats assurés par L'Auxiliaire Vie entre 2020 et 2024
              </span>
              </a>
            </div>

            {/* DROITE */}
            <div>
              <a href={indexFemmesHommes} className="btn-file--2" download>
                <img src={iconDownloadColored} alt="icone download" />
                <span>
                  Consultez l'infographie de notre Index égalité - Femmes/Hommes - 2024
                </span>
              </a>
            </div>
          </div>
        </section>


        {/* FRAIS PER & FRAIS CONTRATS */}
        <section className={"box-chiffres-cles"}>
          <div className={"box-titles"}>
            {/* GAUCHE */}
            <div>
              <h2>
                Les principaux frais attachés aux <strong>PER individuels</strong>
              </h2>
            </div>

            {/* DROITE */}
            <div>
              <h2>
                Les principaux frais attachés aux <strong>contrats d’assurance sur la vie</strong> en cours de commercialisation
              </h2>
            </div>
          </div>

          <div className={"box-contents"}>
            {/* GAUCHE */}
            <div>
              <a href={PERindiviuels} className="btn-file--2" download>
                <img src={iconDownloadColored} alt="icone download" />
                <span>
                  Consultez les taux moyens de rendement et taux moyens d'intérêt garantis des contrats assurés par L'Auxiliaire Vie pour 2020 et 2021
                </span>
              </a>
            </div>

            {/* DROITE */}
            <div>
              <a href={fraisContratVieCommercialisation} className="btn-file--2" download>
                <img src={iconDownloadColored} alt="icone download" />
                <span>
                  Consultez les taux moyens de rendement et taux moyens d'intérêt garantis des contrats assurés par L'Auxiliaire Vie pour 2020 et 2021
                </span>
              </a>
            </div>
          </div>
        </section>


        {/* LOI ENERGIE CLIMAT */}
        <section className={"box-chiffres-cles"}>
          <div className={"box-titles box-solo"}>
            {/* GAUCHE SOLO */}
            <div>
              <h2>
                Rapports <strong>Loi Energie Climat</strong>
              </h2>
            </div>
          </div>

          <div className={"box-contents box-solo"}>
            {/* GAUCHE SOLO */}
            <div>
              <a href={declarationIncidencesNegativesDurabiliteAuxiliaireVie} className="btn-file--2" download>
                <img src={iconDownloadColored} alt="icone download" />
                <span>
                      Consultez la déclaration de non-prise en compte des incidences négatives en matière de durabilité de L'Auxiliaire Vie
                </span>
              </a>

              <a href={rapportArticle29LECAuxiliaireVie} className="btn-file--2" download>
                <img src={iconDownloadColored} alt="icone download" />
                <span>
                      Consultez le rapport Article 29 LEC de L'Auxiliaire Vie
                    </span>
              </a>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export const query = graphql`
  query ChiffresClesPageQuery {
    allStrapiChiffreClef {
      nodes {
        Partenaires {
          Nom
          Description {
            data {
              Description
            }
          }
          Logo {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 80)
              }
            }
          }
        }
      }
    }
  }
`;

export default ChiffresCles;
